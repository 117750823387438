import { Components, Theme } from '@mui/material';
import { palette } from 'mui-theme/palette';

export default {
  defaultProps: {
    MenuProps: {
      PaperProps: {
        sx: {
          backgroundColor: palette.tertiary.main,
        },
      },
    },
  },
  styleOverrides: {
    root: {
      backgroundColor: palette.tertiary.main,
      padding: 0,
    },
  },
} as Components<Theme>['MuiSelect'];
