import MuiAutocomplete from './MuiAutocomplete';
import MuiButton from './MuiButton';
import MuiCheckbox from './MuiCheckbox';
import MuiChip from './MuiChip';
import MuiContainer from './MuiContainer';
import MuiCssBaseline from './MuiCssBaseline';
import MuiDialog from './MuiDialog';
import MuiFormControlLabel from './MuiFormControlLabel';
import MuiIconButton from './MuiIconButton';
import MuiInputBase from './MuiInputBase';
import MuiLink from './MuiLink';
import MuiMenu from './MuiMenu';
import MuiPagination from './MuiPagination';
import MuiRadio from './MuiRadio';
import MuiSelect from './MuiSelect';
import MuiSvgIcon from './MuiSvgIcon';
import MuiTable from './MuiTable';
import MuiTableContainer from './MuiTableContainer';
import MuiTextField from './MuiTextField';
import MuiTypography from './MuiTypography';

export default {
  MuiAutocomplete,
  MuiButton,
  MuiCheckbox,
  MuiChip,
  MuiContainer,
  MuiCssBaseline,
  MuiDialog,
  MuiFormControlLabel,
  MuiIconButton,
  MuiInputBase,
  MuiLink,
  MuiMenu,
  MuiPagination,
  MuiRadio,
  MuiSelect,
  MuiSvgIcon,
  MuiTable,
  MuiTableContainer,
  MuiTextField,
  MuiTypography,
};
